import React, { useCallback, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MESSAGES } from "../../../../../../constants/messages";
import { FIRMWARE, Firmware, Firmware3in1, Files3in1, Operator } from "../../../../../../types/Firmware";
import { MainInput, FileInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ModalBody, HeaderOptionsContainer, HeaderOption, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import DeviceVersionDropdown from "../DeviceVersionDropdown";
import { Helpers } from "../../../../../../helpers";
import { useDevice } from "../../../../../../contexts/device";
import { FirmwareType } from "../../../../../../types/Device";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	handleNewFirmware: (firmware: FIRMWARE, files: File | Files3in1) => void;
	firmwareList: FIRMWARE[] | null;
}

const HEADER_ITEMS = [
	MESSAGES.PT_BR.FIRMWARE.HEADER_SINGLE_FILE,
	MESSAGES.PT_BR.FIRMWARE.HEADER_THREE_IN_ONE
];

const AddFirmwareModal = (props: Props) => {
	const emptyFile = new File([], "");

	const { currentDevice } = useDevice();
	
	const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);
	const [versionError, setVersionError] = useState<string>("");
	const [deviceVersionError, setDeviceVersionError] = useState<string>("");
	const [deviceVersionEndError, setDeviceVersionEndError] = useState<string>("");
	const [androidVersionError, setAndroidVersionError] = useState<string>("");
	const [iosVersionError, setIosVersionError] = useState<string>("");
	const [descriptionError, setDescriptionError] = useState<string>("");
	const [selectedTab, setSelectedTab] = useState(HEADER_ITEMS[0]);
	
	// 1 arquivo
	const [newFirmware, setNewFirmware] = useState<Firmware>({ numOfFiles: 1 } as Firmware);
	const [firmwareFile, setFirmwareFile] = useState<File>(emptyFile);
	const [fileError, setFileError] = useState<string>("");

	// 3 arquivos
	const [newFirmware3in1, setNewFirmware3in1] = useState<Firmware3in1>({ numOfFiles: 3 } as Firmware3in1);
	const [files3in1, setFiles3in1] = useState<Files3in1>({} as Files3in1);
	const [files3in1Error, setFiles3in1Error] = useState({ DATA_TRAN:"", FONT_FILE:"", DFU:"" });

	const handleOnChangeFile3in1 = async (file: File, type: string) => {
		switch (type) {
		case MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_DATA_TRAN:
			setFiles3in1({ ...files3in1, DATA_TRAN: file });
			break;
		case MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_FONT_FILE:
			setFiles3in1({ ...files3in1, FONT_FILE: file });
			break;
		case MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_DFU:
			setFiles3in1({ ...files3in1, DFU: file });
			break;
		}
	};

	const checkNewFirmwareVersionAndDescription = (newFirmware: Firmware | Firmware3in1) => {
		let allRight = true;

		if(!newFirmware.version || newFirmware.version === "") {
			setVersionError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else if(props.firmwareList && props.firmwareList.find(firmware => (firmware.version === newFirmware.version) && (firmware.deviceId == newFirmware.deviceId))) {
			setVersionError(MESSAGES.PT_BR.FIRMWARE.VERSION_ALREADY_EXISTS);
			allRight = false;
		}else{
			setVersionError("");
		}

		const versionChecks = [
			Helpers.Validators.checkVersionAndSetSateError(setAndroidVersionError, newFirmware.androidVersion),
			Helpers.Validators.checkVersionAndSetSateError(setIosVersionError, newFirmware.iosVersion),
			Helpers.Validators.checkVersionAndSetSateError(setDeviceVersionError, newFirmware.deviceVersion),
			Helpers.Validators.checkVersionAndSetSateError(setDeviceVersionEndError, newFirmware.deviceVersionEnd),
		];
		allRight = versionChecks.every((check) => check === true);

		if (newFirmware.deviceVersionOperator) {
			if (!newFirmware.deviceVersion) {
				newFirmware.deviceVersionOperator = undefined;
				newFirmware.deviceVersion = undefined;
				newFirmware.deviceVersionEnd = undefined;
			} else if (newFirmware.deviceVersionOperator === Operator.InBetween && !newFirmware.deviceVersionEnd) {
				setDeviceVersionEndError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
				allRight = false;
			}
		}
		
		if(!newFirmware.description || newFirmware.description === "") {	
			setDescriptionError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else {
			setDescriptionError("");
		}
		return allRight;
	};

	const handleNewFirmware = () => {
		let allRight = checkNewFirmwareVersionAndDescription(newFirmware);

		if (!firmwareFile.size) {
			setFileError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else {
			setFileError("");
		}

		if(allRight){
			setButtonEnabled(false);
			props.handleNewFirmware(newFirmware, firmwareFile);
			resetStates();
		}
	};

	const handleNewFirmware3in1 = () => {
		let allRight = checkNewFirmwareVersionAndDescription(newFirmware3in1);
		
		const newFiles3in1Error = { DATA_TRAN:"", FONT_FILE:"", DFU:"" };
		if (!files3in1.DATA_TRAN) {
			newFiles3in1Error.DATA_TRAN = MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD;
			allRight = false;
		}
		if (!files3in1.FONT_FILE) {
			newFiles3in1Error.FONT_FILE = MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD;
			allRight = false;
		}
		if (!files3in1.DFU) {
			newFiles3in1Error.DFU = MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD;
			allRight = false;
		}
		setFiles3in1Error(newFiles3in1Error);

		if(allRight){
			setButtonEnabled(false);
			props.handleNewFirmware(newFirmware3in1, files3in1);
			resetStates();
		}
	};

	const resetStates = () => {
		setNewFirmware({ numOfFiles: 1 } as Firmware);
		setNewFirmware3in1({ numOfFiles: 3 } as Firmware3in1);
		setFiles3in1({} as Files3in1);
		setButtonEnabled(true);
		setVersionError("");
		setDeviceVersionError("");
		setDeviceVersionEndError("");
		setDescriptionError("");
		setFileError("");
		setFiles3in1Error({ DATA_TRAN:"", FONT_FILE:"", DFU:"" });
		setFirmwareFile(emptyFile);
	};

	const getAvailableInputFileType = useCallback(() => {
		switch (currentDevice?.firmwareType) {
		case FirmwareType.BIN:
			return ".bin";
		case FirmwareType.ZIP:
			return ".zip";
		case FirmwareType.UFW:
			return ".ufw";	
		default:
			return;
		}}, [currentDevice]);

	const getInputTitle = useCallback(() => {
		switch (currentDevice?.firmwareType) {
		case FirmwareType.BIN:
			return MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_BIN;
		case FirmwareType.ZIP:
			return MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_ZIP;
		case FirmwareType.UFW:
			return MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_UFW;	
		default:
			return MESSAGES.PT_BR.FIRMWARE.GENERIC_FILE;
		}}
	, [currentDevice]);	

	const oneFileForm = () => {
		return (
			<>
				<RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_VERSION}
						value={newFirmware.version ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware({ ...newFirmware, version: newValue })}
						errorText={versionError}
					/>
					<h5></h5>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_ID}
						value={newFirmware.deviceId ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.DEVICE_ID_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware({ ...newFirmware, deviceId: newValue })}
					/>
				</RowContainer>
				<DeviceVersionDropdown
					firmware={newFirmware}
					setFirmware={setNewFirmware}
					deviceVersionError={deviceVersionError}
					deviceVersionEndError={deviceVersionEndError}
				/>
				<RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_ANDROID_VERSION}
						value={newFirmware.androidVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.ANDROID_VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware({ ...newFirmware, androidVersion: newValue })}
						errorText={androidVersionError}
					/>
					<h5></h5>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_IOS_VERSION}
						value={newFirmware.iosVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.IOS_VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware({ ...newFirmware, iosVersion: newValue })}
						errorText={iosVersionError}
					/>
				</RowContainer>
				<RowContainer>
					<FileInput
						onChange={setFirmwareFile}
						title={getInputTitle()}
						accept={getAvailableInputFileType()}
						value={firmwareFile.name}
						style={{ margin: 0 }}
						errorText={fileError}
					/>
				</RowContainer>
				<MainInput
					type="text"
					title={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION}
					value={newFirmware.description ?? ""}
					placeholder={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION_PLACEHOLDER}
					onChange={(newValue) => setNewFirmware({ ...newFirmware, description: newValue })}
					errorText={descriptionError}
				/>
			</>
		);
	};

	const threeFilesForm = () => {
		return (
			<>
				<RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_VERSION}
						value={newFirmware3in1.version ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware3in1({ ...newFirmware3in1, version: newValue })}
						errorText={versionError}
					/>
					<h5></h5>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_ID}
						value={newFirmware3in1.deviceId ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.DEVICE_ID_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware3in1({ ...newFirmware3in1, deviceId: newValue })}
					/>
				</RowContainer>
				<DeviceVersionDropdown
					firmware={newFirmware3in1}
					setFirmware={setNewFirmware3in1}
					deviceVersionError={deviceVersionError}
					deviceVersionEndError={deviceVersionEndError}
				/>
				<RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_ANDROID_VERSION}
						value={newFirmware3in1.androidVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.ANDROID_VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware3in1({ ...newFirmware3in1, androidVersion: newValue })}
						errorText={androidVersionError}
					/>
					<h5></h5>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_IOS_VERSION}
						value={newFirmware3in1.iosVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.IOS_VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmware3in1({ ...newFirmware3in1, iosVersion: newValue })}
						errorText={iosVersionError}
					/>
				</RowContainer>
				<RowContainer>
					<FileInput
						onChange={(newFile) => handleOnChangeFile3in1(newFile, MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_DATA_TRAN)}
						title={MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_DATA_TRAN}
						accept={".zip"}
						value={files3in1.DATA_TRAN?.name ?? ""}
						style={{ margin: 0 }}
						errorText={files3in1Error.DATA_TRAN}
					/>
					<h5></h5>
					<FileInput
						onChange={(newFile) => handleOnChangeFile3in1(newFile, MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_FONT_FILE)}
						title={MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_FONT_FILE}
						accept={".fzbin"}
						value={files3in1.FONT_FILE?.name ?? ""}
						style={{ margin: 0 }}
						errorText={files3in1Error.FONT_FILE}
					/>
					<h5></h5>
					<FileInput
						onChange={(newFile) => handleOnChangeFile3in1(newFile, MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_DFU)}
						title={MESSAGES.PT_BR.FIRMWARE.INPUT_FILE_DFU}
						accept={".zip"}
						value={files3in1.DFU?.name ?? ""}
						style={{ margin: 0 }}
						errorText={files3in1Error.DFU}
					/>
				</RowContainer>
				<MainInput
					type="text"
					title={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION}
					value={newFirmware3in1.description ?? ""}
					placeholder={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION_PLACEHOLDER}
					onChange={(newValue) => setNewFirmware3in1({ ...newFirmware3in1, description: newValue })}
					errorText={descriptionError}
				/>
			</>
		);
	};

	return (
		<ModalContainer isOpen={props.isOpen}>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.FIRMWARE.NEW_FIRMWARE_TITLE}</h1>
					<div>
						<CloseIcon onClick={() => {resetStates(); props.onCancel();}} />
					</div>
				</ModalHeader>
				<ModalBody>
					<HeaderOptionsContainer>
						{HEADER_ITEMS.map((item, index) => (
							<HeaderOption
								key={index}
								selected={selectedTab === item}
								onClick={() => {setSelectedTab(item);resetStates();}}>
								<h5>{item}</h5>
							</HeaderOption>
						))}
					</HeaderOptionsContainer>
					{selectedTab == MESSAGES.PT_BR.FIRMWARE.HEADER_SINGLE_FILE ? oneFileForm() : threeFilesForm()}
					<ModalFooter>
						<MainButton
							enabled={buttonEnabled}
							onClick={() => selectedTab == MESSAGES.PT_BR.FIRMWARE.HEADER_SINGLE_FILE ? handleNewFirmware() : handleNewFirmware3in1()}
							text={MESSAGES.PT_BR.FIRMWARE.ADD_BTN}
						/>
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddFirmwareModal;
