export const FIRMWARE = {
	FIRMWARE_TITLE: "Versões do firmware",
	FIRMWARE_EMPTY: "Nenhuma atualização de firmware cadastrada",
	ADD_BTN: "Adicionar",
	SAVE_BTN: "Salvar",

	HEADER_SINGLE_FILE: "1 arquivo",
	HEADER_THREE_IN_ONE: "3 arquivos",
	HEADER_VERSION: "Versão",
	HEADER_ANDROID_VERSION: "Versão app Android",
	HEADER_IOS_VERSION: "Versão app iOS",
	HEADER_DESCRIPTION: "Descrição da atualização",
	HEADER_DATE: "Data",
	HEADER_FILES: "Arquivos",
	HEADER_DEVICE_ID: "DeviceID",
	HEADER_DEVICE_VERSION: "Versão do relógio",
	HEADER_MINIMUM_OS_VERSION: "Versão APP",
	HEADER_ACTIONS: "Ações",

	NEW_FIRMWARE_TITLE: "Adicionar firmware",
	NEW_FIRMWARE_SEQUENTIAL_TITLE: "Adicionar firmware sequencial",
	EDIT_FIRMWARE_TITLE: "Editar firmware",
	DESCRIPTION: "Descrição",
	INPUT_FILE_ZIP: "Firmware.zip",
	INPUT_FILE_UFW: "Firmware.ufw",
	INPUT_FILE_DATA_TRAN: "DATA_TRAN.zip",
	INPUT_FILE_FONT_FILE: "FONT_FILE.fzbin",
	INPUT_FILE_DFU: "DFU.zip",
	INPUT_FILE_BIN: "Firmware.bin",
	GENERIC_FILE: "Arquivo ", 

	VERSION_PLACEHOLDER: "Versão do firmware",
	DEVICE_VERSION_PLACEHOLDER: "Selecionar operador",
	ANDROID_VERSION_PLACEHOLDER: "Versão mínima no Android",
	IOS_VERSION_PLACEHOLDER: "Versão mínima no iOS",
	DEVICE_ID_PLACEHOLDER: "Digite o deviceID",
	VERSION_OS_PLACEHOLDER: "Digite a versão",
	DESCRIPTION_PLACEHOLDER: "O que tem de novo?",

	ANDROID: "Android",
	IOS: "iOS",
	ALL_OS: "Android e IOS",

	PLACEHOLDER_INPUT_TITLE: "Digite o título da notificação",
	PLACEHOLDER_INPUT_CONTENT: "Escreva o conteúdo da notificação",
	PLACEHOLDER_INPUT_LINK: "Link da notificação",
	PLACEHOLDER_INPUT_PHOTO: "Escolher arquivo",
	
	SUCCESS_TITLE: "Firmware adicionado com sucesso!",
	SUCCESS_DESCRIPTION: "Uma nova atualização de firmware foi adicionada com sucesso e já está disponível no aplicativo",

	EDIT_SUCCESS_TITLE: "Firmware editado com sucesso!",
	EDIT_SUCCESS_DESCRIPTION: "O firmware foi editado com sucesso e já está disponível no aplicativo",

	DELETE_MODAL_TITLE: "Tem certeza que deseja remover essa atualização de firmware?",
	DELETE_MODAL_DESCRIPTION: "Caso remova a atualização de firmware, ela será retirada automaticamente do aplicativo do usuário.",

	DELETE_SUCCESS_TITLE: "Firmware removido com sucesso!",
	DELETE_SUCCESS_DESCRIPTION: "A atualização de firmware foi removida com sucesso e não está mais disponível no aplicativo",

	DELETE_ERROR_TITLE: "Erro ao remover firmware",
	DELETE_ERROR_DESCRIPTION: "Erro ao remover firmware. Tente novamente",

	DEFAULT_ERROR_DESCRIPTION: "Erro ao salvar firmware no servidor: ",
	GET_ERROR: "Erro ao recuperar lista de firmwares",
	DEFAULT_ERROR_TITLE: "Erro ao salvar firmware",

	LOADING: "Carregando...",
	UPLOAD_ERROR: "Erro ao recuperar arquivo",
	MANDATORY_FIELD: "Campo obrigatório",
	INVALID_VERSION: "Versão invalida",
	VERSION_ALREADY_EXISTS: "A versão informada já existe",
};