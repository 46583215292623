import React, { useState } from "react";
import { MdOutlineWatch } from "react-icons/md";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../contexts/theme";
import { MESSAGES } from "../../../constants/messages";
import { MainInput, ImageInputWithPreview, MainCheckBox } from "../../../components/Inputs";
import { MainButton } from "../../../components/Buttons";
import { CheckBoxGroup, ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { Device, FirmwareType, GpsType, WatchFaceType } from "../../../types/Device";
import { useDevice } from "../../../contexts/device";
import { useMobileApp } from "../../../contexts/mobileApp";
import { DEVICE_FEATURES, FEATURES } from "../../../constants/features";
import { OptionBaseProps } from "../../Inputs/CheckBox";

interface ModalProps {
	onCancel: () => void;
	onClose: (feedback) => void;
}

const NewDeviceModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { createNewDevice } = useDevice();
	const { selectedApp } = useMobileApp();

	const [newDevice, setNewDevice] = useState<Device>({ availableFeatures: DEVICE_FEATURES, watchFaceType: WatchFaceType.ZIP, firmwareType: FirmwareType.ZIP, gpsType: GpsType.AGPS } as Device);
	const [deviceNameError, setDeviceNameError] = useState<string>("");
	const [slugError, setSlugError] = useState<string>("");
	const [imageFile, setImageFile] = useState<File>(new File([], ""));

	const handleSaveNewDevice = () => {
		let allRight = true;

		if (newDevice?.name === undefined || newDevice?.name?.length === 0) {
			setDeviceNameError(MESSAGES.PT_BR.DEVICE_MODAL.MANDATORY_FIELD);
			allRight = false;
		} else {
			setDeviceNameError("");
		}

		if (newDevice?.slug === undefined || newDevice?.slug?.length === 0) {
			setSlugError(MESSAGES.PT_BR.DEVICE_MODAL.MANDATORY_FIELD);
			allRight = false;
		} else {
			setSlugError("");
		}

		if (allRight) {
			let feedback = {};
			createNewDevice(newDevice, imageFile, selectedApp.appCode).then(() => {
				feedback = {
					isOpen: true,
					title: MESSAGES.PT_BR.DEVICE_MODAL.NEW_DEVICE_FEEDBACK_TITLE,
					description: MESSAGES.PT_BR.DEVICE_MODAL.NEW_DEVICE_FEEDBACK_DESCRIPTION,
					success: true
				};
			}).catch((errorMessage) => {
				feedback = {
					isOpen: true,
					title: MESSAGES.PT_BR.DEVICE_MODAL.NEW_DEVICE_FEEDBACK_TITLE_ERROR,
					description: MESSAGES.PT_BR.DEVICE_MODAL.NEW_DEVICE_FEEDBACK_DESCRIPTION_ERROR + errorMessage,
					success: false
				};
			}).finally(() => {
				props.onClose(feedback);
			});
		}
	};

	const getSubOptions = (feature: string) => {
		const subOptions: OptionBaseProps[] = [];
		if (newDevice?.availableFeatures?.includes(feature)) {
			switch (feature) {
			case FEATURES.WATCH_FACES:
				subOptions.push(
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_ZIP,
						checked: newDevice.watchFaceType === WatchFaceType.ZIP,
						onChange: () => setNewDevice({ ...newDevice, watchFaceType: WatchFaceType.ZIP })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_BIN,
						checked: newDevice.watchFaceType === WatchFaceType.BIN,
						onChange: () => setNewDevice({ ...newDevice, watchFaceType: WatchFaceType.BIN })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_WATCH,
						checked: newDevice.watchFaceType === WatchFaceType.WATCH,
						onChange: () => setNewDevice({ ...newDevice, watchFaceType: WatchFaceType.WATCH })
					},
				);
				break;
			case FEATURES.FIRMWARE:
				subOptions.push(
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.SEQUENTIAL,
						checked: newDevice.firmwareType === FirmwareType.SEQUENTIAL,
						onChange: () => setNewDevice({ ...newDevice, firmwareType: FirmwareType.SEQUENTIAL })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_ZIP,
						checked: newDevice.firmwareType === FirmwareType.ZIP,
						onChange: () => setNewDevice({ ...newDevice, firmwareType: FirmwareType.ZIP })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_BIN,
						checked: newDevice.firmwareType === FirmwareType.BIN,
						onChange: () => setNewDevice({ ...newDevice, firmwareType: FirmwareType.BIN })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_UFW,
						checked: newDevice.firmwareType === FirmwareType.UFW,
						onChange: () => setNewDevice({ ...newDevice, firmwareType: FirmwareType.UFW })
					}
				);
				break;
			case FEATURES.GPS:
				subOptions.push(
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_AGPS,
						checked: newDevice.gpsType === GpsType.AGPS,
						onChange: () => setNewDevice({ ...newDevice, gpsType: GpsType.AGPS })
					},
					{
						label: MESSAGES.PT_BR.DEVICE_MODAL.TYPE_EPO,
						checked: newDevice.gpsType === GpsType.EPO,
						onChange: () => setNewDevice({ ...newDevice, gpsType: GpsType.EPO })
					},
				);
			}
		}
		return subOptions;
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.DEVICE_MODAL.NEW_DEVICE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel}/>
					</div>
				</ModalHeader>
				<ModalBody >
					<ImageInputWithPreview
						onSelectImage={setImageFile}
						image={imageFile.size? URL.createObjectURL(imageFile) : undefined}
						defaultImageComponent={<MdOutlineWatch color={currentTheme.textColor} size={"60%"} />}
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_NAME}
						placeholder={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_NAME_PLACEHOLDER}
						value={newDevice?.name || ""}
						errorText={deviceNameError}
						onChange={deviceName => setNewDevice({ ...newDevice, name: deviceName })}
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_SLUG}
						placeholder={MESSAGES.PT_BR.DEVICE_MODAL.DEVICE_SLUG_PLACEHOLDER}
						value={newDevice?.slug || ""}
						errorText={slugError}
						onChange={newSlug => setNewDevice({ ...newDevice, slug: newSlug.replace(" ", "").toLocaleLowerCase() })}
						style={{ marginTop: "10px", marginBottom: "10px" }}
					/>
					<h1>{MESSAGES.PT_BR.DEVICE_MODAL.FUNCTIONS}</h1>
					<CheckBoxGroup>
						{
							DEVICE_FEATURES.map((currentFeature, index) => (
								<MainCheckBox
									key={index}
									label={currentFeature}
									checked={newDevice?.availableFeatures?.includes(currentFeature)}
									onChange={checked => {
										if (!newDevice?.availableFeatures?.includes(currentFeature) && checked) {
											setNewDevice({ ...newDevice, availableFeatures: [...newDevice?.availableFeatures || [], currentFeature] });
										} else if (newDevice?.availableFeatures?.includes(currentFeature) && !checked) {
											setNewDevice({ ...newDevice, availableFeatures: newDevice?.availableFeatures.filter((feature) => feature !== currentFeature) });
										}
									}}
									subOptions={getSubOptions(currentFeature)}
								/>
							))
						}
					</CheckBoxGroup>
					<MainButton enabled onClick={handleSaveNewDevice} text={MESSAGES.PT_BR.DEVICE_MODAL.ADD_BTN} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default NewDeviceModal;