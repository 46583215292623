
/**
    API Object
    {
        "id": 0,
        "name": "string",
        "slug": "string",
        "createdAt": "2023-02-07T17:55:39.696Z",
        "updatedAt": "2023-02-07T17:55:39.696Z",
        "link_manual": "string",
        "available_features": [
            "string"
        ],
        "image": "string"
    }
 */

import { FirmwareType, GpsType, WatchFaceType } from "../Device";

/**
  APP Object
    {
        name: string;
        slug: string;
        image?: string;
        linkManual?: string;
        availableFeatures: string[];
    }
 */

const fromApiToModel = (device) => {
	return {
		name: device.name,
		slug: device.slug,
		linkManual: device.link_manualS3 || "",
		linkManualCDN: device.link_manual || "",
		availableFeatures: device?.available_features || [],
		image: device.imageS3,
		imageCDN: device.image || "/images/t_technos.png",
		watchFaceType: getApiWatchFaceType(device?.available_features),
		firmwareType: device?.available_features?.length && getApiFirmwareType(device.available_features),
		gpsType: device?.available_features?.length && getApiGpsType(device.available_features)
	};
};

const getApiWatchFaceType = (availableFeatures) => {
	if (!Array.isArray(availableFeatures) || availableFeatures.length === 0)
		return WatchFaceType.ZIP;

	if (availableFeatures.includes(WatchFaceType.BIN))
		return WatchFaceType.BIN;
	
	if (availableFeatures.includes(WatchFaceType.WATCH))
		return WatchFaceType.WATCH;

	return WatchFaceType.ZIP;
};

const getApiFirmwareType = (available_features) => {
	if(available_features.includes(FirmwareType.UFW)) return FirmwareType.UFW;
	if (available_features.includes(FirmwareType.BIN)) return FirmwareType.BIN;
	if (available_features.includes(FirmwareType.ZIP)) return FirmwareType.ZIP;
	if (available_features.includes(FirmwareType.SEQUENTIAL)) return FirmwareType.SEQUENTIAL;
	return false;
};

const getApiGpsType = (available_features) => {
	if (available_features.includes(GpsType.AGPS)) return GpsType.AGPS;
	if (available_features.includes(GpsType.EPO)) return GpsType.EPO;
	return GpsType.AGPS;
};

const getAvailableFeatures = (device) => {
	const { watchFaceType, firmwareType, gpsType, availableFeatures } = device;
    
	const filterOut = (features, toRemove) => features.filter(item => !toRemove.includes(item));
    
	let filteredFeatures = filterOut(availableFeatures, [
		watchFaceType === WatchFaceType.ZIP ? [WatchFaceType.BIN, WatchFaceType.WATCH] :
			watchFaceType === WatchFaceType.WATCH ? [WatchFaceType.BIN, WatchFaceType.ZIP] :
				[WatchFaceType.ZIP, WatchFaceType.WATCH],
        
		firmwareType === FirmwareType.ZIP ? [FirmwareType.BIN, FirmwareType.UFW] :
			firmwareType === FirmwareType.BIN ? [FirmwareType.ZIP, FirmwareType.UFW] :
				[FirmwareType.BIN, FirmwareType.ZIP],
        
		gpsType === GpsType.AGPS ? [GpsType.EPO] : [GpsType.AGPS]
	].flat());
    
	[watchFaceType, firmwareType, gpsType].forEach(type => {
		if (!filteredFeatures.includes(type)) {
			filteredFeatures.push(type);
		}
	});
    
	return filteredFeatures.filter(Boolean);
};

const fromModelToApi = (device) => {
	return {
		name: device.name,
		slug: device.slug,
		image: device?.image || "",
		linkManual: device?.linkManual || "",
		availableFeatures: getAvailableFeatures(device),
	};
};

export const DeviceMapper = {
	fromApiToModel,
	fromModelToApi
};
