export const CHANGELOG = {
	"1.0.0": {
		"date": "09/03/2023",
		"changes": [
			"Lançamento inicial"
		]
	},
	"1.0.1": {
		"date": "27/03/2023",
		"changes": [
			"Correção do bug de mostradores",
			"Correção de bugs na atualização de perfil",
			"Finalização dos itens pendentes em `Exercício customizado`"
		]
	},
	"1.0.2": {
		"date": "28/03/2023",
		"changes": [
			"Correção de imagens no ambiente publicado",
			"Correção de bugs de alteração de senha",
		]
	},
	"1.0.3": {
		"date": "28/03/2023",
		"changes": [
			"Correção do bug de mostrar no app - Mostradores",
			"Correção do bug de carregamento do Sleep Score",
		]
	},
	"1.0.4": {
		"date": "29/03/2023",
		"changes": [
			"137534 - Seções e quantidade de downloads cortados no gráfico",
			"140082 - Bug 140082: Cor de seleção de item na lista escondendo o nome do item",
			"140083 - Modal de Editar Aula não abre",
			"140084 - Cores do texto explicativo das ações no modo claro impossibilita leitura",
			"Correção do bug de criação de Faq sem tags",
		]
	},
	"1.0.5": {
		"date": "31/03/2023",
		"changes": [
			"145624 - Quantidade de downloads da seção incorreto",
			"145627 - Página de dados de mostradores não atualiza ao trocar de dispositivo",
			"145629 - Barra do gráfico incorreta",
			"140575 - Faq não atualiza corretamente",
			"140835 - Alteração de Manual resulta em mudança de dispositivo"
		]
	},
	"1.0.6": {
		"date": "03/04/2023",
		"changes": [
			"Correção da cor do texto em modo escuro na modal `sobre`",
			"Alteração da imagem incorreta no header da tela de usuários do webAdmin",
			"Alterando componentes de edit e delete da tela de usuários do webAdmin para exibir infos quando passar o mouse",
			"Correção do layout do preview de notificações quando ultrapassa o limite de caracters",
			"Correção da imagem da AWS quebrada na tela de suporte",
			"Correção da feature de email de suporte e adição de um botão para testar o envio de email",
			"Correção do fluxo de edição de um treino em Treine com a Mormaii",
			"139389, 139400, 139396 - Raio e tamanho incorreto nos inputs",
			"139391 - Cor diferente da esperada quando passa o mouse sobre o componente",
		]
	},
	"1.0.7": {
		"date": "04/04/2023",
		"changes": [
			"Correção do texto de feedback em editar treino",
			"139382 - Adição do arquivo staticwebapp.config.json para configuração das rotas no Azure",
		]
	},
	"1.0.8": {
		"date": "12/04/2023",
		"changes": [
			"Adicionando Regex para remover espaços em branco nos arquivos enviados ao S3",
		]
	},
	"1.0.9": {
		"date": "13/04/2023",
		"changes": [
			"Finalizando configuração do ambiente de homologação",
			"Correção de bugs de layout no modo escuro",
		]
	},
	"1.0.10": {
		"date": "18/04/2023",
		"changes": [
			"Alterando cor do texto incorreto na tela de firmware para o tema escuro"
		]
	},
	"1.0.11": {
		"date": "12/05/2023",
		"changes": [
			"Adiciona opção de link em Termos e Políticas",
		]
	},
	"1.0.12": {
		"date": "26/05/2023",
		"changes": [
			"Alterando urls para publicação em Produção",
		]
	},
	"1.0.13": {
		"date": "05/06/2023",
		"changes": [
			"Corrige a ordenação dos mostradores e seções",
			"Corrige o layout para seção de mostrador vazia",
		]
	},
	"1.0.14": {
		"date": "06/06/2023",
		"changes": [
			"Aplicando melhorias nos gráficos de mostradores",
		]
	},
	"1.0.15": {
		"date": "07/06/2023",
		"changes": [
			"Corrigindo valores trocados na tabela de sleep",
		]
	},
	"1.1.0": {
		"date": "20/06/2023",
		"changes": [
			"Adicionando Permission Gate e tratatives de cargos no webAdmin",
			"156028 - No modo escuro o “tema” do FAQ está ainda com a cor do modo claro",
			"156036 - O botão de continuar adicionando da tela de vídeos tutoriais está com a cor do tema claro no tema escuro.",
			"156037 - Texto do popup de excluir vídeo do vídeo tutorial está com cor do tema claro.",
			"156043 - No popup de editar senha a label está com a cor do texto do modo claro.",
			"156044 - Botões estão fora da caixa branca quando exclui mostrador"
		]
	},
	"1.1.1": {
		"date": "22/06/2023",
		"changes": [
			"Corrige cor de fundo do editor HTML em termos e políticas",
			"156029 - Não consegue adicionar novo tema no FAQ",
			"156040 - Mostradores ordenados por download",
			"156038 - Alterando formato do mostrador para png sem máscara",
			"156031 - Cor do texto em políticas de privacidade"
		]
	},
	"1.1.2": {
		"date": "23/06/2023",
		"changes": [
			"156039 - Dar a possibilidade de adicionar um arquivo de mostrador arrastando o arquivo pro campo",
			"156027 - A ordenação dos cards do FAQ não está funcionando",
			"156032 - A ordenação dos treinos e o mostrar no app não está funcionando.",
			"156034 - Quando da um scroll nos dispositivos, a tooltip dos botões ficam considerando a posição inicial.",
			"156046 - Não consegue adicionar novo mostrador"
		]
	},
	"1.1.3": {
		"date": "30/06/2023",
		"changes": [
			"156020 - Corrigindo edição de perfil para qualquer cargo",
		]
	},
	"1.1.4": {
		"date": "23/08/2023",
		"changes": [
			"Corrigindo cor do texto para tema escuro em seções de mostradores",
			"Corrigindo feedback de exibir no app em modal de adicionar mostrador",
		]
	},
	"1.1.5": {
		"date": "31/08/2023",
		"changes": [
			"Corrigindo bug em que mostrador fixo aparece com 'mostrar no app' desabilitado",
		]
	},
	"1.1.6": {
		"date": "04/09/2023",
		"changes": [
			"Adicionando suporte a arquivos .bin para mostradores",
		]
	},
	"1.1.7": {
		"date": "08/09/2023",
		"changes": [
			"Bugfix: Erro ao adicionar mostradores - Index com tipo incorreto",
		]
	},
	"1.1.8": {
		"date": "08/09/2023",
		"changes": [
			"Adicionando suporte a deleção de arquivos de firmware",
		]
	},
	"1.1.9": {
		"date": "12/09/2023",
		"changes": [
			"Bugfix: Correção do tipo de mostrador - Bin ou Zip",
		]
	},
	"1.1.10": {
		"date": "12/09/2023",
		"changes": [
			"Bugfix: Corrigindo formatador de nome do arquivo",
		]
	},
	"1.1.11": {
		"date": "13/09/2023",
		"changes": [
			"Bugfix: Corrigindo layout da modal de deletar mostrador",
		]
	},
	"1.1.12": {
		"date": "14/09/2023",
		"changes": [
			"Alterando credenciais da AWS para nova conta",
		]
	},
	"1.1.13": {
		"date": "18/09/2023",
		"changes": [
			"Atualiza modal de informações legais para políticas e termos",
		]
	},
	"1.1.14": {
		"date": "26/09/2023",
		"changes": [
			"Envia tamanho de arquivo de firmware para o backend",
			"Adiciona ícones dark e light ao tema de faq"
		]
	},
	"1.1.15": {
		"date": "27/09/2023",
		"changes": [
			"Bugfix: Altera regex de validação de arquivo de firmware",
		]
	},
	"1.1.16": {
		"date": "27/09/2023",
		"changes": [
			"Bugfix: Corrige criação de artigo de FAQ",
		]
	},
	"1.1.17": {
		"date": "04/10/2023",
		"changes": [
			"Adição de recurso de acessibilidade no campo input",
		]
	},
	"1.1.18": {
		"date": "05/10/2023",
		"changes": [
			"Bugfix 9530: Correção de modal - Criar e limpar campos.",
		]
	},
	"1.1.19": {
		"date": "20/10/2023",
		"changes": [
			"Bugfix 9530: Erro ao continuar adicionando artigos de FAQ",
		]
	},
	"1.1.20": {
		"date": "22/12/2023",
		"changes": [
			"Adiciona form para atualização de 3 arquivos do firmware, #9525",
			"Inclusão de Default WatchFace"
		]
	},
	"1.1.21": {
		"date": "26/02/2024",
		"changes": [
			" Adiciona versão do sistema operacional ao firmware e modal de edição",
			"Adicionando verificação da versão do OS na modal de adição de firmware",
		]
	},
	"1.1.22": {
		"date": "04/03/2024",
		"changes": [
			"Adiciona opção de filtro para os downloads dos mostradores",
			"Corrige deviceId com string vazia",
		]
	},
	"1.1.23": {
		"date": "08/03/2024",
		"changes": [
			"Alterando chaves do AWS S3"
		]
	},
	"1.1.24": {
		"date": "28/03/2024",
		"changes": [
			"Adicionando suporte ao firmware sequencial"
		]
	},
	"1.1.25": {
		"date": "28/05/2024",
		"changes": [
			"Adicionando Compartilhamento de Exercício"
		]
	},
	"1.1.26": {
		"date": "29/07/2024",
		"changes": [
			"Adiciona suporte a mostradores com extensão .watch"
		]
	},
	"1.1.27": {
		"date": "05/08/2024",
		"changes": [
			"Adiciona suporte ao EPO e vincula dados de GPS ao smartwatch"
		]
	},
	"1.1.28": {
		"date": "07/08/2024",
		"changes": [
			"Corrige bug ao cadastrar funcionalidades do dispositivo"
		]
	},
	"1.2.0": {
		"date": "08/08/2024",
		"changes": [
			"Nova API da AWS",
			"Corrige S3_BUCKET nos ambientes de DEV e PROD"
		]
	},
	"1.2.1": {
		"date": "30/08/2024",
		"changes": [
			"Adicionar filtro de modelo no FAQ",
		]
	},
	"1.2.2": {
		"date": "04/10/2024",
		"changes": [
			"Avaliação do FAQ",
			"Filtro de modelo no FAQ",
			"Correção de bugs",
		]
	},
	"1.2.3": {
		"date": "14/10/2024",
		"changes": [
			"Atualiza Keycloak client",
		]
	},
	"1.2.4": {
		"date": "19/02/2025",
		"changes": [
			"Adiciona suporte a mostradores com extensão .ufw",
		]
	},
	"1.3.0": {
		"date": "24/02/2025",
		"changes": [
			"Adição do condor na lista de apps para produção",
		]
	}
};
